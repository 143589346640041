export default [
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('@/views/Organizations/Index.vue'),
    meta: {
      title: "Organizations - Submit"
    }
  },
  {
    path: '/organizations/create',
    name: 'Organizations.Create',
    component: () => import('@/views/Organizations/Create.vue'),
    meta: {
      title: "Create Organization - Submit",
      admin: true
    }
  }
]
