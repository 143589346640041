export default [
  {
    path: '/reporting',
    name: 'Reporting',
    component: () => import('@/views/Reporting/Index.vue'),
    meta: {
      title: "Reporting - Submit"
    }
  }
]
