export default [
  {
    path: '/kinks',
    name: 'Kinks',
    component: () => import('@/views/Kinks/Index.vue'),
    children: [
      {
        path: '',
        name: 'Kinks.Kinks',
        component: () => import('@/views/Kinks/Kinks.vue'),
        meta: {
          title: "Kinks - Submit"
        }
      },
      {
        path: 'yours',
        name: 'Kinks.Yours',
        component: () => import('@/views/Kinks/Yours.vue'),
        meta: {
          title: "Your Kinks - Submit"
        }
      },
      {
        path: 'all',
        name: 'Kinks.All',
        component: () => import('@/views/Kinks/All-Kinks.vue'),
        meta: {
          title: "All Kinks - Submit"
        }
      },
      {
        path: '/interests',
        name: 'Kinks.Interests',
        component: () => import('@/views/Kinks/Interests.vue'),
        meta: {
          title: "Interests - Submit"
        }
      },
      {
        path: '/interests/all',
        name: 'Kinks.All-Interests',
        component: () => import('@/views/Kinks/All-Interests.vue'),
        meta: {
          title: "All Interests - Submit"
        }
      },
      {
        path: '/kinks/manage',
        name: 'Kinks.Management',
        component: () => import('@/views/Kinks/Manage.vue'),
        meta: {
          title: "Kinks Management - Submit",
          admin: true
        }
      },
      {
        path: ':slug',
        name: 'Kinks.Kink',
        component: () => import('@/views/Kinks/Kink.vue')
      },
      {
        path: '/interests/:slug',
        name: 'Kinks.Interest',
        component: () => import('@/views/Kinks/Interest.vue')
      }
    ]
  }
]
