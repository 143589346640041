export default [
  {
    path: '/council',
    name: 'Council',
    component: () => import('@/views/Council/Index.vue'),
    children: [
      {
        path: '',
        name: 'Council.Overview',
        component: () => import('@/views/Council/Overview.vue'),
        meta: {
          title: "Community Council - Submit"
        }
      }
    ]
  }
]
