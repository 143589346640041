export default [
  {
    path: '/explore',
    name: 'Explore',
    component: () => import('@/views/Explore/Index.vue'),
    children: [
      {
        path: '',
        name: 'Explore.Media',
        component: () => import('@/views/Explore/Content.vue'),
        meta: {
          title: 'Explore Media - Submit'
        }
      },
      {
        path: 'new',
        name: 'Explore.New',
        component: () => import('@/views/Explore/New.vue'),
        meta: {
          title: 'Explore New Content - Submit'
        }
      },
      {
        path: 'trending',
        name: 'Explore.Trending',
        component: () => import('@/views/Explore/Trending.vue'),
        meta: {
          title: 'Explore Trending - Submit'
        }
      },
      {
        path: 'writings',
        name: 'Explore.Writings',
        component: () => import('@/views/Explore/Writings.vue'),
        meta: {
          title: 'Explore Writings - Submit'
        }
      }
    ]
  }
]
