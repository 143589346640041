export default [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: "Home - Submit"
    },
    children: [
      {
        path: "",
        name: "Home.Following",
        component: () => import('@/views/Feeds/Feed.vue'),
        props: { feedContext: "all" }
      },
      {
        path: "groups",
        name: "Home.Groups",
        component: () => import('@/views/Feeds/Feed.vue'),
        props: { feedContext: "groups" }
      },
      {
        path: "/circles/:code",
        name: "Home.Circle",
        component: () => import('@/views/Feeds/Feed.vue'),
        props: (route: any) => ({ feedContext: route.params.code })
      }
    ]
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import('@/views/Notifications/Index.vue'),
    meta: {
      title: "Notifications - Submit"
    }
  },
  {
    path: "/messages",
    name: "Messages",
    component: () => import('@/views/Messages/Index.vue'),
    meta: {
      title: "Messages - Submit"
    },
    children: [
      {
        path: "",
        name: "Messages.Inbox",
        component: () => import('@/views/Messages/Inbox.vue')
      },
      {
        path: "sent",
        name: "Messages.Sent",
        component: () => import('@/views/Messages/Sent.vue')
      },
      {
        path: "filtered",
        name: "Messages.Filtered",
        component: () => import('@/views/Messages/Filtered.vue')
      },
      {
        path: "archives",
        name: "Messages.Archives",
        component: () => import('@/views/Messages/Archives.vue')
      },
      {
        path: "notices",
        name: "Messages.Notices",
        component: () => import('@/views/Messages/Notices.vue')
      },
      {
        path: ":id",
        name: "Messages.Thread",
        component: () => import('@/views/Messages/Thread.vue')
      }
    ]
  },
  {
    path: "/vault",
    name: "Vault",
    component: () => import('@/views/Vault/Index.vue'),
    meta: {
      title: "Vault - Submit"
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import('@/views/Settings/Index.vue'),
    meta: {
      title: "Settings - Submit"
    },
    children: [
      {
        path: "",
        name: "Settings.Index",
        component: () => import('@/views/Settings/Settings.vue')
      },
      {
        path: "profile/details",
        name: "Settings.Profile.Details",
        component: () => import('@/views/Settings/Profile/Details.vue'),
        meta: {
          title: "Profile Details - Settings - Submit"
        }
      },
      {
        path: "profile/about",
        name: "Settings.Profile.About",
        component: () => import('@/views/Settings/Profile/About.vue'),
        meta: {
          title: "Profile About - Settings - Submit"
        }
      },
      {
        path: "profile/photo",
        name: "Settings.Profile.Photo",
        component: () => import('@/views/Settings/Profile/Photo.vue'),
        meta: {
          title: "Profile Photo - Settings - Submit"
        }
      },
      {
        path: "profile/theme",
        name: "Settings.Profile.Theme",
        component: () => import('@/views/Settings/Profile/Theme.vue'),
        meta: {
          title: "Profile Theme - Settings - Submit"
        }
      },
      {
        path: "privacy/settings",
        name: "Settings.Privacy.Settings",
        component: () => import('@/views/Settings/Privacy/Settings.vue'),
        meta: {
          title: "Privacy Settings - Settings - Submit"
        }
      },
      {
        path: "privacy/circles",
        name: "Settings.Privacy.Circles",
        component: () => import('@/views/Settings/Privacy/Circles.vue'),
        meta: {
          title: "Circles - Settings - Submit"
        }
      },
      {
        path: "privacy/starterpacks",
        name: "Settings.Privacy.StarterPacks",
        component: () => import('@/views/Settings/Privacy/StarterPacks.vue'),
        meta: {
          title: "Starter Packs - Settings - Submit"
        }
      },
      {
        path: "privacy/filters",
        name: "Settings.Privacy.Filters",
        component: () => import('@/views/Settings/Privacy/Filters.vue'),
        meta: {
          title: "Filters - Settings - Submit"
        }
      },
      {
        path: "privacy/blocks",
        name: "Settings.Privacy.Blocks",
        component: () => import('@/views/Settings/Privacy/Blocks.vue'),
        meta: {
          title: "Blocks - Settings - Submit"
        }
      },
      {
        path: "privacy/preblocks",
        name: "Settings.Privacy.Preblocks",
        component: () => import('@/views/Settings/Privacy/PreBlocks.vue'),
        meta: {
          title: "Pre-Blocks - Settings - Submit"
        }
      },
      {
        path: "relationships",
        name: "Settings.Relationships",
        component: () => import('@/views/Settings/Relationships.vue'),
        meta: {
          title: "Relationships - Settings - Submit"
        }
      },
      {
        path: "account/settings",
        name: "Settings.Account.Settings",
        component: () => import('@/views/Settings/Account.vue'),
        meta: {
          title: "Account Settings - Settings - Submit"
        }
      },
      {
        path: "account/standing",
        name: "Settings.Account.Standing",
        component: () => import('@/views/Settings/Standing.vue'),
        meta: {
          title: "Account Standing - Settings - Submit"
        }
      },
      {
        path: "preferences",
        name: "Settings.Preferences",
        component: () => import('@/views/Settings/Preferences.vue'),
        meta: {
          title: "Preferences - Settings - Submit"
        }
      },
      {
        path: "security",
        name: "Settings.Security",
        component: () => import('@/views/Settings/Security.vue'),
        meta: {
          title: "Security - Settings - Submit"
        }
      },
      {
        path: "verification",
        name: "Settings.Verification",
        component: () => import('@/views/Settings/Verification.vue'),
        meta: {
          title: "Verification - Settings - Submit"
        }
      },
      {
        path: "backer",
        name: "Settings.Backer",
        component: () => import('@/views/Settings/Backer.vue'),
        meta: {
          title: "Backer - Settings - Submit"
        }
      },
      {
        path: "notifications",
        name: "Settings.Notifications",
        component: () => import('@/views/Settings/Notifications.vue'),
        meta: {
          title: "Notifications - Settings - Submit"
        }
      }
    ]
  }
]
