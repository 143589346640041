export default [
  {
    path: '/platform',
    name: 'Submit',
    component: () => import('@/views/Submit/Index.vue'),
    children: [
      {
        path: '',
        name: 'Submit.Overview',
        component: () => import('@/views/Submit/Submit.vue'),
        meta: {
          title: " - Submit"
        }
      },
      {
        path: '/announcements',
        name: 'Submit.Announcements',
        component: () => import('@/views/Submit/Announcements.vue'),
        meta: {
          title: "Announcements - Submit"
        }
      },
      {
        path: '/announcements/new',
        name: 'Submit.Announcement.New',
        component: () => import('@/views/Submit/NewAnnouncement.vue'),
        meta: {
          title: "New Announcement - Submit",
          admin: true
        }
      },
      {
        path: '/announcements/:slug',
        name: 'Submit.Announcement',
        component: () => import('@/views/Submit/Announcement.vue')
      },
      {
        path: '/announcements/:slug/edit',
        name: 'Submit.Announcement.Edit',
        component: () => import('@/views/Submit/EditAnnouncement.vue'),
        meta: {
          title: "Edit Announcement - Submit",
          admin: true
        }
      },
      {
        path: '/changelog',
        name: 'Submit.Changelog',
        component: () => import('@/views/Submit/Changelog.vue'),
        meta: {
          title: "Changelog - Submit"
        }
      },
      {
        path: '/roadmap',
        name: 'Submit.Roadmap',
        component: () => import('@/views/Submit/Roadmap.vue'),
        meta: {
          title: "Roadmap - Submit"
        }
      },
      {
        path: '/goals',
        name: 'Submit.Goals',
        component: () => import('@/views/Submit/Goals.vue'),
        meta: {
          title: "Goals - Submit"
        }
      }
    ]
  }
]
