export default [
  {
    path: '/reservations',
    name: 'Reservations',
    component: () => import('@/views/Reservations/Index.vue'),
    children: [
      {
        path: '',
        name: 'Reservations.Overview',
        component: () => import('@/views/Reservations/Overview.vue'),
        meta: {
          title: "Reservations - Submit"
        }
      },
      {
        path: 'groups',
        name: 'Reservations.Groups',
        component: () => import('@/views/Reservations/Groups.vue'),
        meta: {
          title: "Group Reservations - Submit"
        }
      },
      {
        path: 'Organizations',
        name: 'Reservations.Organizations',
        component: () => import('@/views/Reservations/Organizations.vue'),
        meta: {
          title: "Organization Reservations - Submit"
        }
      }
    ]
  }
]
