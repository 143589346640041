export default [
  {
    path: '/relationships',
    name: 'Relationships',
    component: () => import('@/views/Relationships/Index.vue'),
    meta: {
      admin: true
    },
    children: [
      {
        path: "",
        name: "Relationships.Overview",
        component: () => import('@/views/Relationships/Overview.vue'),
        meta: {
          title: "Relationships - Submit",
          admin: true
        }
      },
      {
        path: "types",
        name: "Relationships.Types",
        component: () => import('@/views/Relationships/Types.vue'),
        meta: {
          title: "Relationship Types - Submit",
          admin: true
        }
      }
    ]
  }
]
