export default [
  {
    path: '/l',
    name: 'Locations',
    component: () => import('@/views/Locations/Locations.vue'),
    meta: {
      title: "Locations - Submit"
    }
  },
  {
    path: '/l/:location+',
    name: 'Location',
    component: () => import('@/views/Locations/Location.vue')
  }
]
