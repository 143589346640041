export default [
  {
    path: '/e/:event',
    name: 'Event',
    component: () => import('@/views/Event/Index.vue'),
    children: [
      {
        path: '',
        name: 'Event.Details',
        component: () => import('@/views/Event/Details.vue')
      },
      {
        path: 'updates',
        name: 'Event.Updates',
        component: () => import('@/views/Event/Updates.vue')
      },
      {
        path: 'management/inbox',
        name: 'Event.Management.Inbox',
        component: () => import('@/views/Event/Inbox.vue')
      },
      {
        path: 'management/requests',
        name: 'Event.Management.Requests',
        component: () => import('@/views/Event/Requests.vue')
      },
      {
        path: 'management/settings',
        name: 'Event.Management.Settings',
        component: () => import('@/views/Event/Settings.vue')
      },
      {
        path: 'related',
        name: 'Event.Related',
        component: () => import('@/views/Event/Related.vue')
      },
      {
        path: 'management/users',
        name: 'Event.Management.Users',
        component: () => import('@/views/Event/Users.vue')
      }
    ]
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/Events/Index.vue'),
    children: [
      {
        path: '',
        name: 'Events.Discover',
        component: () => import('@/views/Events/Discover.vue'),
        meta: {
          title: "Events - Submit"
        }
      },
      {
        path: 'yours',
        name: 'Events.Yours',
        component: () => import('@/views/Events/Yours.vue'),
        meta: {
          title: "Your Events - Submit"
        }
      },
      {
        path: 'create',
        name: 'Events.Create',
        component: () => import('@/views/Events/Create.vue'),
        meta: {
          title: "Create Event - Submit"
        }
      }
    ]
  }
]
