export default [
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search/Index.vue'),
    meta: {
      title: "Search - Submit"
    }
  }
]
