export default [
  {
    path: '/:username',
    name: 'Profile',
    component: () => import('@/views/Profile/Index.vue'),
    children: [
      {
        path: "",
        name: "Profile.Profile",
        component: () => import('@/views/Profile/Profile.vue')
      },
      {
        path: "activity",
        name: "Profile.Activity",
        component: () => import('@/views/Profile/Activity.vue')
      },
      {
        path: "followers",
        name: "Profile.Followers",
        component: () => import('@/views/Profile/Followers.vue')
      },
      {
        path: "following",
        name: "Profile.Following",
        component: () => import('@/views/Profile/Following.vue')
      },
      {
        path: "media",
        name: "Profile.Media",
        component: () => import('@/views/Profile/Media.vue'),
        children: [
          {
            path: "",
            name: "Profile.Media.Photos",
            component: () => import('@/views/Profile/Media/Photos.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "collections",
            name: "Profile.Media.Collections",
            component: () => import('@/views/Profile/Media/Collections.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "collections/:slug",
            name: "Profile.Media.Collections.Collection",
            component: () => import('@/views/Profile/Media/Photos.vue'),
            meta: {
              subSubLoading: true
            }
          }
        ]
      },
      {
        path: "media/:id",
        name: "Profile.Media.Media",
        component: () => import('@/views/Profile/Media/Media.vue')
      },
      {
        path: "writing",
        name: "Profile.Writing",
        component: () => import('@/views/Profile/Writing.vue'),
        children: [
          {
            path: "",
            name: "Profile.Writing.Writings",
            component: () => import('@/views/Profile/Writing/Writings.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "drafts",
            name: "Profile.Writing.Drafts",
            component: () => import('@/views/Profile/Writing/Drafts.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "collections",
            name: "Profile.Writing.Collections",
            component: () => import('@/views/Profile/Writing/Collections.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "collections/:slug",
            name: "Profile.Writing.Collections.Collection",
            component: () => import('@/views/Profile/Writing/Writings.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "new",
            name: "Profile.Writing.New",
            component: () => import('@/views/Profile/Writing/New.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: ":slug",
            name: "Profile.Writing.Writing",
            component: () => import('@/views/Profile/Writing/Writing.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: ":slug/edit",
            name: "Profile.Writing.Edit",
            component: () => import('@/views/Profile/Writing/Edit.vue'),
            meta: {
              subSubLoading: true
            }

          }
        ]
      },
      {
        path: "status/:id",
        name: "Profile.Status",
        component: () => import('@/views/Profile/Status/Status.vue')
      },
      {
        path: "events",
        name: "Profile.Events",
        component: () => import('@/views/Profile/Events.vue'),
        children: [
          {
            path: "",
            name: "Profile.Events.Attending",
            component: () => import('@/views/Profile/Events/Attending.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "considering",
            name: "Profile.Events.Considering",
            component: () => import('@/views/Profile/Events/Considering.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "created",
            name: "Profile.Events.Created",
            component: () => import('@/views/Profile/Events/Created.vue'),
            meta: {
              subSubLoading: true
            }
          },
          {
            path: "past",
            name: "Profile.Events.Past",
            component: () => import('@/views/Profile/Events/Past.vue'),
            meta: {
              subSubLoading: true
            }
          }
        ]
      }
    ]
  }
]
