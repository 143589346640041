export default [
  {
    path: '/starter-packs',
    name: 'StarterPacks',
    component: () => import('@/views/StarterPacks/Index.vue'),
    meta: {
      title: "Starter Packs - Submit"
    }
  },
  {
    path: '/starter-packs/yours',
    name: 'YourStarterPacks',
    component: () => import('@/views/StarterPacks/YourPacks.vue'),
    meta: {
      title: "Your Starter Packs - Submit"
    }
  },
  {
    path: '/starter-packs/create',
    name: 'CreateStarterPack',
    component: () => import('@/views/StarterPacks/Create.vue'),
  },
  {
    path: '/starter-packs/:pack',
    name: 'StarterPack',
    component: () => import('@/views/StarterPacks/Pack.vue'),
  }
]
